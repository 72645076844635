export const Doamin_url = `${window.location.origin}/`;




// export const base_url = `http://localhost:5001/`;
// export const soket_url = `http://localhost:5001/`;
// export const image_baseurl = `https://stockboxpnp.pnpuniverse.com/`;





export const soket_url = `${window.location.origin}:1001/`;
export const base_url = `${window.location.origin}/backend/`
export const image_baseurl = `${window.location.origin}/`;
